var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mew-overview pt-5" }, [
    _c(
      "div",
      {
        staticClass: "mx-auto",
        staticStyle: { "max-width": "650px", width: "100%" },
      },
      [
        _c(
          "div",
          { staticClass: "mb-5" },
          [
            _c(
              "mew-button",
              {
                staticClass: "CreateWalletSoftwareOverviewKeystore",
                staticStyle: { height: "160px" },
                attrs: {
                  "has-full-width": "",
                  "color-theme": "greyMedium",
                  "btn-style": "outline",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.selectWalletType(_vm.walletTypes.KEYSTORE)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-left d-flex align-center justify-space-between px-2",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "mew-heading-2 textDark--text mb-2" },
                        [_vm._v("Keystore File")]
                      ),
                      _c("div", { staticClass: "break-word textDark--text" }, [
                        _vm._v(
                          " Using a keystore file online makes your wallet more vulnerable to loss of funds. We don’t recommend this method of wallet creation. "
                        ),
                      ]),
                    ]),
                    _c("img", {
                      staticClass: "mx-4 d-none d-sm-block",
                      attrs: {
                        width: "80",
                        src: require("@/assets/images/icons/icon-keystore-file.svg"),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "CreateWalletSoftwareOverviewMnemonic mb-5" },
          [
            _c(
              "mew-button",
              {
                staticStyle: { height: "160px" },
                attrs: {
                  "has-full-width": "",
                  "color-theme": "greyMedium",
                  "btn-style": "outline",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.selectWalletType(_vm.walletTypes.MNEMONIC)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-left d-flex align-center justify-space-between px-2",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "mew-heading-2 textDark--text mb-2" },
                        [_vm._v(" Mnemonic Phrase ")]
                      ),
                      _c("div", { staticClass: "break-word textDark--text" }, [
                        _vm._v(
                          " Using a Mnemonic Phrase online makes your wallet more vulnerable to loss of funds. We don’t recommend this method of wallet creation. "
                        ),
                      ]),
                    ]),
                    _c("img", {
                      staticClass: "mx-4 d-none d-sm-block",
                      attrs: {
                        width: "80",
                        src: require("@/assets/images/icons/icon-mnemonic.svg"),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }